import {
  ButtonApple,
  ButtonFacebook,
  ButtonGoogle,
  IconsHorizontal,
} from '@hungryroot/sso';
import { islandRoot } from '../IslandRoot/IslandRoot';

function redirectToMyHungryroot() {
  window.location.href = '/myhungryroot/';
}

function Sso() {
  return (
    <IconsHorizontal
      buttonApple={
        <ButtonApple
          onLoginSuccess={redirectToMyHungryroot}
          onSignupSuccess={redirectToMyHungryroot}
          onError={() => {
            // todo: handle sso errors
          }}
        />
      }
      buttonGoogle={
        <ButtonGoogle
          onLoginSuccess={redirectToMyHungryroot}
          onSignupSuccess={redirectToMyHungryroot}
          onError={() => {
            // todo: handle sso errors
          }}
        />
      }
      buttonFacebook={
        <ButtonFacebook
          onLoginSuccess={redirectToMyHungryroot}
          onSignupSuccess={redirectToMyHungryroot}
          onError={() => {
            // todo: handle sso errors
          }}
        />
      }
    />
  );
}

export const SsoButtonsIsland = islandRoot(Sso);
